import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import { Helmet } from 'react-helmet'
import Button from 'components/button/button'
import Layout from 'components/layout'
import Content from 'components/new/content'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import Spacer from 'components/new/spacer'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const CommitmentToOwnershipAndRepairPage = props => {
  const {
    data: {
      heroImage,
      healthAndSafetyImage,
      environmentAndSustainabilityImage,
      industryGuidelinesImage,
    },
  } = props
  return (
    <Layout>
      <Helmet>
        <title>Commitment to Ownership &amp; Repair | Hutson Inc</title>
      </Helmet>

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='Commitment to Ownership &amp; Repair'
        overlayOpacity={0.3}
      />

      <Content kind='full'>
        <H2>Our principles</H2>
        <P>
          “Let’s grow together.” You may have heard this mission statement of ours before. We know
          that without you, we would be out of business. That is why our mission is, and always has
          been, to provide you with reliable equipment and exceptional support.
        </P>
        <P>
          We are a John Deere dealer because we believe they offer the best equipment and support in
          the industry. We employ John Deere certified technicians that know the equipment inside
          and out. Service is a big part of our business, and we are proud we can continue to
          support our customers after the sale. While that is true,{' '}
          <strong>we believe our customers should have the right to repair their machines</strong>;
          whether they choose to have Hutson perform the repair, do it themselves, or use a third
          party.
        </P>
        <P>
          We understand the frustration of owning a piece of equipment that you cannot maintain.
          John Deere understands that too, so they provide detailed product information and
          technical solutions for owners to keep their machines running. Our team is here to support
          you in whatever way possible.
        </P>
        <P>
          Although we support our customer’s ability to repair their machines,{' '}
          <strong>we do not support the modification of these machines</strong>. John Deere takes
          the health and safety of their customers and the environment seriously. They engineer
          their equipment with highly technical and environmentally friendly standards in mind. That
          is why we, and John Deere, cannot support altering machines from their engineered
          standards. Such modifications can pose a risk to you as an owner, your operators, future
          owners, Hutson employees, and the environment.
        </P>
        <P>
          If you have questions about ownership and repair resources, please reach out to{' '}
          <Link to='/locations/'>your local Hutson store</Link>. For more information about industry
          commitments on repair, please visit the{' '}
          <OutboundLink href='https://r2rsolutions.org/'>Right to Repair website</OutboundLink>.
        </P>
        <Spacer />
        <H2>Additional resources</H2>
        <Spacer size='m' />
        <Grid>
          <Column>
            <GatsbyImage image={healthAndSafetyImage.childImageSharp.gatsbyImageData} alt='' />
            <Spacer size='m' />
            <H3>Health and Safety</H3>
            <P>
              Find tips, videos, and other resources from John Deere about safely using and
              repairing their equipment.
            </P>
            <Spacer size='s' />
            <ButtonContainer>
              <Button
                as={OutboundLink}
                href='https://www.deere.com/en/parts-and-service/safety/'
                ghost
                color='green'
              >
                Learn more about health and safety
              </Button>
            </ButtonContainer>
          </Column>
          <Column>
            <GatsbyImage
              image={environmentAndSustainabilityImage.childImageSharp.gatsbyImageData}
              alt=''
            />
            <Spacer size='m' />
            <H3>Environment and Sustainability</H3>
            <P>Learn more about John Deere's commitment to sustainability.</P>
            <Spacer size='s' />
            <ButtonContainer>
              <Button
                as={OutboundLink}
                href='https://www.deere.com/en/our-company/sustainability/'
                ghost
                color='green'
              >
                View John Deere's Sustainability Report
              </Button>
            </ButtonContainer>
          </Column>
          <Column>
            <GatsbyImage image={industryGuidelinesImage.childImageSharp.gatsbyImageData} alt='' />
            <Spacer size='m' />
            <H3>Industry Guidelines</H3>
            <P>
              Find more information about Right to Repair and keep up to date on the latest news.
            </P>
            <Spacer size='s' />
            <ButtonContainer>
              <Button as={OutboundLink} href='https://r2rsolutions.org/' ghost color='green'>
                Visit the Right to Repair website
              </Button>
            </ButtonContainer>
          </Column>
        </Grid>
      </Content>
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

const ButtonContainer = styled.div`
  a {
    display: inline-block;
    text-decoration: none;
  }
`

const Grid = styled.div`
  @media (min-width: 900px) {
    ${clearfix}
  }
`

const Column = styled.div`
  background-color: #fff;

  @media (max-width: 899px) {
    :not(:last-child) {
      margin-bottom: ${props => props.theme.size.l};
    }
  }

  @media (min-width: 900px) {
    ${column('1/3')}
  }
`

export const pageQuery = graphql`
  {
    heroImage: file(
      relativePath: {
        eq: "commitment-to-ownership-and-repair/commitment-to-ownership-and-repair-hero.jpg"
      }
    ) {
      ...FullWidthImage
    }
    healthAndSafetyImage: file(
      relativePath: { eq: "commitment-to-ownership-and-repair/health-and-safety.jpg" }
    ) {
      ...SharpImage480
    }
    environmentAndSustainabilityImage: file(
      relativePath: { eq: "commitment-to-ownership-and-repair/environment-and-sustainability.jpg" }
    ) {
      ...SharpImage480
    }
    industryGuidelinesImage: file(
      relativePath: { eq: "commitment-to-ownership-and-repair/industry-guidelines.jpg" }
    ) {
      ...SharpImage480
    }
  }
`

export default CommitmentToOwnershipAndRepairPage
